<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import { mapState } from "vuex";
import { VclList } from "vue-content-loading";
import { required, minLength } from "vuelidate/lib/validators";

export default {
  locales: {
    pt: {
      'Tickets': 'Tickets',
      'New Ticket': 'Novo Ticket',
      'Subject': 'Assunto',
      'Subject is required.': 'O assunto é obrigatório.',
      'Message': 'Mensagem',
      'Message is required.': 'A mensagem é obrigatório.',
      'Send': 'Enviar',
      'Date': 'Data',
      'Status': 'Status',
      'View': 'Ver',
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.',
      'No records found.': 'Nenhum registro encontrado.',
    },
    es: {
      'Tickets': 'Tickets',
      'New Ticket': 'Nuevo Ticket',
      'Subject': 'Asunto',
      'Subject is required.': 'El asunto se requiere.',
      'Message': 'Mensaje',
      'Message is required.': 'El mesaje se requiere.',
      'Send': 'Enviar',
      'Date': 'Data',
      'Status': 'Status',
      'View': 'View',
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.',
      'No records found.': 'No se encontraron registros.',
    }
  },
  components: { Layout, VclList },
  data() {
    return {
      body: null,
      loading: true,
      errored: false,
      empty: false,

      ticket: {
        modal: false,
        loading: false,
        alert: {
          type: '',
          message: ''
        },
        subject: '',
        description: '',
      }
    };
  },
  computed: {
    ...mapState({
      account: state => state.account
    })
  },
  validations: {
    ticket: {
      subject: { required },
      description: { required, minLength: minLength(2) }
    },
  },
  methods: {
    getTickets() {
      api
        .get('ticket')
        .then(response => {
          if (response.data.status=='success') {
            this.body = response.data.list
          } else {
            this.body = null;
          }
        })
        .catch(error => {
          this.errored = error
        })
        .finally(() => {
          this.loading = false
          if (this.body=='' || this.body==null) {
            this.empty = true
          }
        })
    },
    ticketSubmit() {
      this.$v.ticket.$touch();

      if (!this.$v.ticket.$error && this.ticket.subject && this.ticket.description) {
        this.ticket.loading = true

        api
          .post('ticket',{
            subject: this.ticket.subject,
            description: this.ticket.description
          })
          .then(response => {
            if (response.data.status=='success') {
              this.ticket.subject = ''
              this.ticket.description = ''
              this.$v.ticket.$reset()

              this.ticket.alert.type = 'alert-success'
              this.ticket.alert.message = 'The ticket has been created, wait for contact.'

              this.ticket.modal = false
              this.$router.push('/tickets/'+response.data.ticket.id)
            }
          })
          .catch(error => {
            this.ticket.errored = error

            this.ticket.alert.type = 'alert-danger'
            this.ticket.alert.message = 'The ticket could not be created, please try again.'

            this.ticket.loading = false
          })
          .finally(() => {
          })
      }

    }
  },
  mounted() {
    this.getTickets()
  }
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t('Tickets') }}</h4>
          <div class="page-title-right">
            <button
              type="button"
              class="btn btn-success btn-sm text-uppercase"
              @click="ticket.modal = true"
            ><i class="bx bx-conversation font-size-16 align-middle mr-1"></i> {{ t('New Ticket') }}</button>
          </div>
        </div>
      </div>
    </div>

    <b-modal v-model="ticket.modal" :title="t('New Ticket')" centered>
      <b-form @submit.prevent="ticketSubmit">
        <div v-if="ticket.alert.message" :class="'alert ' + ticket.alert.type">{{ ticket.alert.message }}</div>
        <b-form-group id="ticket-subject" :label="t('Subject')" label-for="ticket-subject">
          <b-form-input id="ticket-subject" v-model="ticket.subject" type="text" :class="{ 'is-invalid': $v.ticket.subject.$error }" autocomplete="off"></b-form-input>
          <div v-if="$v.ticket.subject.$error" class="invalid-feedback">
            <span v-if="!$v.ticket.subject.required">{{ t('Subject is required.') }}</span>
          </div>
        </b-form-group>
        <b-form-group id="ticket-description" :label="t('Message')" label-for="ticket-description">
          <b-form-textarea id="ticket-description" v-model="ticket.description" rows="5" max-rows="6" :class="{ 'is-invalid': $v.ticket.description.$error }"></b-form-textarea>
          <div v-if="$v.ticket.description.$error" class="invalid-feedback">
            <span v-if="!$v.ticket.description.required || !$v.ticket.description.minLength">{{ t('Message is required.') }}</span>
          </div>
        </b-form-group>
        <b-button :disabled="ticket.loading == true || this.ticket.subject.length<2 || this.ticket.description.length<2 || $v.ticket.$error" type="submit" variant="success">
          {{ t('Send') }}
          <b-spinner v-if="ticket.loading" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
        </b-button>
      </b-form>
      <template v-slot:modal-footer>
        <div></div>
      </template>
    </b-modal>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div v-if="errored">{{ t('We apologize, we are unable to retrieve information at this time. Please try again later.') }}</div>
            <div v-else-if="empty">{{ t('No records found.') }}</div>
            <div v-else class="table-responsive">
              <vcl-list v-if="loading" class="col-lg-6"></vcl-list>
              <table v-else class="table table-centered table-nowrap">
                <thead class="thead-light">
                  <tr>
                    <th></th>
                    <th>#</th>
                    <th>{{ t('Subject') }}</th>
                    <th>{{ t('Date') }}</th>
                    <th>{{ t('Status') }}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(td,index) in body" :key="index">
                    <td>
                      <img
                        v-if="account.user.avatar"
                        class="rounded-circle header-profile-user"
                        :src="account.user.avatar"
                        alt=""
                      />
                    </td>
                    <td class="notranslate">{{ td.id }}</td>
                    <td class="notranslate">{{ td.subject }}</td>
                    <td class="notranslate"><i class="mdi mdi-clock-outline font-size-18 align-middle"></i> {{ td.date }}</td>
                    <td>{{ td.status }}</td>
                    <td class="text-right">
                      <router-link :to="'/tickets/'+td.id" class="btn btn-success btn-sm btn-rounded">
                        {{ t('View') }}
                        <i class="mdi mdi-arrow-right ml-1"></i>
                      </router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>